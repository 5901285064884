@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Rockwell', sans-serif;
  font-style: italic;
}
html {
  height: 100%;
  margin: 0;
}
body {
  min-height: 100%;
}

.App {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.app-background {
  background: linear-gradient(0deg, #fff, #e8fce6 70.71%),
              linear-gradient(0deg, #dffadb,#f4fdf3 70.71%),
              linear-gradient(0deg, #f4fdf3, #d0f9ca 70.71%);
  height: 100vh;
}

.heading-1 {
  display: grid;
  grid-template-columns: 100px 1fr 100px;
  align-content: space-evenly;
  justify-content: center;
  align-items: start;
  justify-items: stretch;
}

.header-img {
  mix-blend-mode: multiply;
}

.action-bar {
  display: grid;
  grid-template-rows: 1fr 20px;
  align-content: space-evenly;
  justify-content: flex-start;
  align-items: start;
  justify-items: flex-start;
  grid-row-gap: 1ch;
  row-gap: 1ch;
}

.image-list {
/*   display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-content: flex-start;
  justify-content: space-evenly;
  align-items: baseline;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 3px; */
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px;
  overflow-x: hidden;
  justify-content: center;

/*   display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px; */
}

.img-container {
  width: 150px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  cursor: pointer;
}

.img-fluid {
  width: 100%;
  height: auto;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

video {
  width: 100%;
  height: auto;
}

.video-player {
  pointer-events: none;
}

